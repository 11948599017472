@import "src/js/global.scss";


.Header {
  //border: 5px solid red;
  height: 200px;

  img {
    //border: 5px solid pink;
    //margin-left: $sidePadding_big;
    height: 80%;
    margin-top: 20px;
  }
}


@media only screen and (max-width: $breakpoint-width-tablet) {
  body {
    .Header {
      //background: $headerBgColor;
      height: 100px;

      img {
        //border: 5px solid pink;
        //margin-left: 2px;
        height: 60%;
        margin-top: 20px;
      }
    }
  }
}


