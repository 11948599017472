
@import "../global";

.services-section {
  padding:30px;
  h2 {
    color: $standardTextColor;
  }
  //border: 5px solid green;
  p {
    padding-top: 0rem;
    margin-top: 0rem;
  }
  background-color: white;
  font-family: Arial, Helvetica, sans-serif;
  color: $standardTextColor;

  .image {
    height: 400px;
    width: 400px;
    //mask :
    -webkit-mask-image: url(../../img/splatter2.svg);
    mask-image: url(../../img/splatter2.svg);
    -webkit-mask-size: 100%;
    mask-size: 100%;
    -webkit-mask-repeat: no-repeat;
    mask-position: bottom;
  }
  padding-bottom: 200px;
}

.services-section.hidden {
  opacity: 0;
  transition: all 1s;
  filter: blur(5px);
  transform: translateX(-100px);
}

.services-section.show {
  background-color: white;
  z-index: 2;
  opacity: 1;
  transition: all 2s;
  filter: blur(0);
  transform: translateX(0);
}

#coaching {
  padding-bottom: 400px;
}

#web-image {
  float: right;
  border: 5px solid red;
  background: url("../../img/laptop4.png");
  background-size: cover;
}

#uml-image {
  float: left;
  border: 5px solid red;
  background: url("../../img/uml.png");
  background-color: gray;
  background-size: cover;
}

#coaching-image {
  float: right;
  border: 5px solid red;
  background: url("../../img/coaching.jpg");
  background-size: cover;
}




//WIDE
@media only screen and (min-width: $breakpoint-width-normal) {

}
$imgChartWidthN: 500px;



//NORMAL
@media only screen and (min-width: $breakpoint-width-tablet) and (max-width: $breakpoint-width-normal) {

}


$imgChartWidthT: 600px;

//TABLET
@media only screen and (min-width: $breakpoint-width-phone) and (max-width: $breakpoint-width-tablet) {
  .services-section {
    .image {
      height: 200px;
      width: 200px;
    }
  }
}

//PHONE
@media only screen and (max-width: $breakpoint-width-phone) {
  .services-section {
    .image {
      height: 200px;
      width: 200px;
    }
  }
}

