@import "../global";


$navBackgroundColor: #0033ff;
$navSelectedColor: #1995dc;
$navHeight: 52px;


nav {
  background-image: -webkit-linear-gradient(#54b4eb, #2fa4e7 60%, #1d9ce5);
  background-image: -o-linear-gradient(#54b4eb, #2fa4e7 60%, #1d9ce5);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#54b4eb), color-stop(60%, #2fa4e7), to(#1d9ce5));
  background-image: linear-gradient(#54b4eb, #2fa4e7 60%, #1d9ce5);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff54b4eb', endColorstr='#ff1d9ce5', GradientType=0);
  border-bottom: 1px solid #178acc;
  -webkit-filter: none;
  //filter: none;
  -webkit-box-shadow: 0 1px 10px rgba(0,0,0,0.1);
  box-shadow: 0 1px 10px rgba(0,0,0,0.1);
  margin-bottom: 50px;
}

.playground {
  opacity: 5%;
}

.standard-menu, .standard-sub-menu, .hamburger-menu {
  list-style: none;
}

.standard-menu, .standard-sub-menu{
  font-size: 0;
  margin: 0;
  padding: 0;
  text-align: center;
  height: $navHeight;
}

.navigation-element {
  //border: 2px solid cadetblue;
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative; //need for submenu
  float: left;

  a {
    display: block;
    color: white;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 16px;
    padding: 14px;
    text-decoration: none;
  }
}

.hamburger-icon-item, .language {
  padding: 16px;
}

nav {

  padding-left: $sidePadding_normal;
  padding-right: $sidePadding_normal;

  li #hamburger-icon{
    background-image: url("../../img/Hamburger_icon.svg");
    min-height: 20px;
    width:30px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .standard-menu {
    li:hover ul {
      opacity: 1;
      top: 50px;
      visibility: visible;
    }
    .active {
      background: #1995dc;
    }
  }

  .art{
    float: right;
  }

  .language{
    float: right;
    //padding: 16px;
    width:30px;

    #german {
      min-height: 20px;
      background-image: url("../../img/germany-flag-icon.svg");
      background-repeat: no-repeat;
    }

    #english {
      min-height: 20px;
      background-image: url("../../img/united-kingdom-flag-icon.svg");
      background-repeat: no-repeat;
    }
  }

  .dropdown:after {
    //arrow
    content: ' \25bc';
  }
}

nav li #hamburger-icon:hover{
  background-color: $navSelectedColor;
  cursor: pointer;
}

.hamburger-icon-item {
  display:none;
}

.standard-menu-item:hover {
  background-color: $navSelectedColor;
}

li.language:hover{
  background-color: $navSelectedColor;
  cursor: pointer;
}


.language.active {
  background: #1995dc;
}

.standard-sub-menu {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 35px;
  visibility: hidden;
  z-index: 1;
  a:hover {
    background: $navBackgroundColor;
  }
  a {
    background: $navSelectedColor;
  }
  li {
    width: 100%;
  }
}

.hamburger-menu, .hamburger-menu-item, .hamburger-sub-menu {
  //background-color: white;
  list-style: none;
}

.hamburger-menu {
  background-color: white;

  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 22px;

  text-decoration: none;

  margin: 0;
  padding: 0;

  .hamburger-menu-item {
    background: #ffffff;
    //border-bottom: 1px;
    display:block;
    a {
      color:$navSelectedColor;
      text-decoration: none;
      padding: 4px;
    }

    border-bottom: 1px solid rgba(128, 128, 128, 0.37);
  }

  .dropdown:after {
    //arrow
    content: '';
  }

  .hamburger-menu-item.active {
    background: #e0d6d6;
  }
  .hamburger-sub-menu-item {
    display:block;
    font-size: 18px;
    visibility: visible;
    border-bottom: 1px solid rgba(128, 128, 128, 0.21);

  }
}

//navigation-element
$mobileHeight: 35px;
@media only screen and (max-width: $breakpoint-width-tablet) {
  body {
    nav {

      padding: 0;
      margin-left: 2px;

      .hamburger-menu-item {
        display:block;

      }
      .hamburger-sub-menu-item {
        display:block;
      }


      .standard-menu-item {
        display:none;
      }
      .standard-menu {
        height: $navHeight - 15;
        padding-top: 0px;
      }

      .navigation-element {
        height: $navHeight - 25;
      }
      .navigation-element a {
        font-size: 14px;
      }

      .language {
        padding: 5px;
        display:block;
      }
      .hamburger-icon-item {
        padding: 5px 5px 0px 5px;
        display:block;
      }
    }
  }
}



@media only screen and (max-width: $breakpoint-width-phone) {
  //body {
  //  nav {
  //    border: 5px solid burlywood;
  //    margin-left: 2px;
  //    padding-bottom: 0px;
  //
  //    li.language {
  //      padding: 5px;
  //    }
  //
  //    ul {
  //      height: $navHeight - 10;
  //      padding-top: 0px;
  //    }
  //
  //    ul li a {
  //      height: $navHeight - 10;
  //      font-size: 14px;
  //      padding-right: 5px;
  //      padding-left: 5px;
  //    }
  //  }
  //}
}

