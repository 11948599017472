

#header-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px;
}

#header-title-icharts {
  color: #555454;
  background-image: url("img/icharts-banner.PNG");
  font-family: "Calisto MT", "Century Gothic", Arial, Helvetica;
  background-size: 1401px 1401px;
  font-size: 80px;
  margin: 40px 0px 10px 10px;
  text-shadow: 2px 2px rgba(128, 128, 128, 0.24);
  background-repeat: no-repeat;
  border-radius: 25px
}

#header-blurb {
  margin: 1px 15px 15px 20px;
  font-family: Arial, Helvetica;
  font-size: 20px;
  color: #3a4a55;
  //text-shadow: 2px 2px rgba(128, 128, 128, 0.24);
}