@import "../global";

.fa-page-home {
  //border: 5px solid green;
  p {
    margin: 2rem;
    //font-size: 1.5rem;
    font-family: Arial, Helvetica, sans-serif;
    color: $standardTextColor;
  }

  .home-page-info {
    padding: 2rem;
  }

  .home-page-image {
    float: right;
    margin-left: 2rem;
    min-width: 45vw;
    aspect-ratio: 3 / 2;

    //background image :
    border: 5px solid red;
    background: url("../../img/Fotolia_107797505_L.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top;

    //mask :
    -webkit-mask-image: url(../../img/splatter2.svg);
    mask-image: url(../../img/splatter2.svg);
    -webkit-mask-size: 73%;
    mask-size: 73%;
    -webkit-mask-repeat: no-repeat;
    mask-position: top;
  }
}

@media only screen and (max-width: $breakpoint-width-tablet) {
  body {
    .fa-page-home {
      p {
        //font-size: 1.3rem;
        font-family: Arial, Helvetica, sans-serif;
      }

      .home-page-info {
        padding: .1rem;
        width: 100%;
      }
    }
  }
}


@media only screen and (max-width: $breakpoint-width-phone) {
  body {
    .fa-page-home {
      p {
        margin: 1rem;
        //font-size: 1rem;
        font-family: Arial, Helvetica, sans-serif;
      }

      .home-page-info {
        padding: 0.5rem;
        width: 100%;
      }
    }
  }
}
