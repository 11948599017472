@import "../global";


.contact-page {
  //border: 5px solid purple;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.contact-page > * { flex: 1; } //makes them all equal width

.contact-info {
  p {
    padding: 2rem 2rem 2rem 2rem;
    margin-top: 0rem;
    //font-size: 26px;
    text-align: right;
  }

  font-family: Arial, Helvetica, sans-serif;
  color: $standardTextColor;
}

.contact-page-image {
  //border: 5px solid red;
  background: url("../../img/Fotolia_107797505_L.jpg");
  background-size: contain;
  background-repeat: no-repeat;

  margin: auto;
  min-width: 25vw;
  max-width: 25vw;
  aspect-ratio: 3 / 2;

  -webkit-mask-image: url(../../img/splatter.svg);
  mask-image: url(../../img/splatter.svg);
  -webkit-mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-size: cover;

  -webkit-mask-position: 100px -65px;
  mask-position: 100px -65px;
}


.contact-page-links {
  //border: 5px solid blue;
  display: flex;
  justify-content: flex-end;
}
.contact-page-links img{
  //border: 5px solid pink;
  width: 100px;
  margin: 1rem 1rem 1rem 1rem;
}

.contact-form-container {

  /* Add a background color and some padding around the form */
  border-radius: 5px;
  //background-color: #f2f2f2;
  background: #ccc;

  padding: 20px;
  input[type=text], select, textarea {
    width: 100%; /* Full width */
    padding: 12px; /* Some padding */
    border: 1px solid #ccc; /* Gray border */
    border-radius: 4px; /* Rounded borders */
    box-sizing: border-box; /* Make sure that padding and width stays in place */
    margin-top: 6px; /* Add a top margin */
    margin-bottom: 16px; /* Bottom margin */
    resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
  }

  /* Style the submit button with a specific background color etc */
  input[type=submit] {
    background-color: #04AA6D;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  /* When moving the mouse over the submit button, add a darker green color */
  input[type=submit]:hover {
    background-color: #45a049;
  }
}


@media only screen and (max-width: $breakpoint-width-tablet) {
  .contact-page {
    flex-direction: column;
  }

  //kill image
  .contact-page-image {
    max-width: 0.0px;
    width: 0.0px;
    min-width: 0.0px;
  }
}
