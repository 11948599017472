@import "../global";

$imgChartWidthN: 500px;
$imgChartWidthT: 400px;
$imgChartWidthP: 300px;

.previous-work.fa-page {
  background: white;
  z-index: -3;
}

.previous-work {
  .hidden {
    .previous-work-description {
      opacity: 0;
      transition: all 1s;
      filter: blur(5px);
      transform: translateX(-100px);
    }
    .boxy{
      opacity: 1;
      transition: all 1s;
      filter: blur(5px);
      transform: translateX(-100px);
    }
  }
  .show {
    .previous-work-description {
      //border: 5px solid green;
      background-color: white;
      z-index: 2;
      opacity: 1;
      transition: all 2s;
      filter: blur(0);
      transform: translateX(0);
    }

    .boxy{

      z-index: 2;
      //border: 5px solid green;
      opacity: 1;
      transition: all 1s;
      filter: blur(0);
      transform: translateX(0);
      background-color: white;
    }
  }

  padding: 2rem;

  .previous-work-top {
    p {
      padding-top: 0rem;
      margin-top: 0rem;
      text-align: right;
    }
    background-color: white;
    font-family: Arial, Helvetica, sans-serif;
    color: $standardTextColor;
    margin-bottom: 200px;
  }

  .prev-section.left {
    //border: 5px solid green;
    .previous-work-description {
      ul {
        direction: rtl;
      }
      p {
        text-align: right;
      }
    }
  }
  .prev-section.right {
    .previous-work-description {
      ul {
        direction: ltr;
      }
      p {
        text-align: left;
      }
    }
    flex-direction: row-reverse;
  }
  .prev-section {
    z-index: 3;
    display: flex;
    justify-content: flex-end;
    max-height: 500px;

    .previous-work-description {
      padding: 0rem 2rem 2rem 2rem;
      p {
        padding-top: 0rem;
        margin-top: 0rem;
        text-align: right;
      }
      font-family: Arial, Helvetica, sans-serif;
      color: $standardTextColor;
    }
  }
}

.image-gallery {
  width : $imgChartWidthN;
  //border: 5px solid orange;
  background-color: white;
}

//NORMAL
@media only screen and (min-width: $breakpoint-width-tablet) {
//and (max-width: $breakpoint-width-normal) {

  .image-gallery {
    //max-height : 100px;
    width : $imgChartWidthN;
    //border: 3px dashed blue;
  }
  #react-charts-img {
    width : $imgChartWidthN;
    height: 100%;
  }

  #art-gallery-img {
    width : 700px;
    height: 100%;
  }

  #blog-img {
    width : $imgChartWidthN;
    height: 100%;
  }
  .prev-section {
    //border: 3px dashed blue;
    margin-bottom: 500px;
  }
  .prev-section-top {
    margin-bottom: 0px;
  }
  .previous-work {
    .prev-section.right,
    .prev-section {
      max-height : 500px;
      min-height : 500px;
      //border: 5px solid pink;
      display: flex;
      justify-content: flex-end;
    }
  }
}

//TABLET
@media only screen and (min-width: $breakpoint-width-phone) and (max-width: $breakpoint-width-tablet) {

  .image-gallery {
    //max-height : 100px;
    width : $imgChartWidthT;
    //border: 3px dashed blue;
  }
  #react-charts-img {
    width : $imgChartWidthT;
    height: 100%;
  }
  #blog-img {
    width : $imgChartWidthT;
    height: 100%;
  }
  .previous-work {
    .prev-section.right,
    .prev-section {
      flex-direction: column;
      justify-content: normal;
    }
  }
}

//PHONE
@media only screen and (max-width: $breakpoint-width-phone) {
  .previous-work {
    //border: 3px dashed blue;
    .prev-section.right,
    .prev-section {
      //border: 3px dashed blue;
      flex-direction: column;
      justify-content: normal;
    }
  }
  .prev-section {
    //border: 3px dashed blue;
    margin-bottom: 250px;
  }
  .prev-section-top {
    margin-bottom: 250px;
  }

  .boxy {
    //border: 3px dashed blue;
  }

  .image-gallery {
    //max-height : 100px;
    //width : $imgChartWidthT;
    width : $imgChartWidthP;
    //border: 3px dashed blue;
  }
  #react-charts-img {
    width : $imgChartWidthP;
    height: 100%;
  }
  #blog-img {
    width : $imgChartWidthP;
    height: 100%;
  }
}