#tabs ul {
  list-style: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
#tabs li {
  float: left;
  border: 1px solid #000;
  border-bottom-width: 0;
  margin: 3px 3px 0px 3px;
  padding: 5px 5px 0px 5px;
  background-color: #CCC;
  color: #696969;
}

#tabs .active {
  background-color: #FFF;
  color: #000;
}

#mainView {
  clear:left;
  background-color: #FFF;
}