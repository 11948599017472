@import "../global";

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.fa-page {
  //border: 5px solid green;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: .3s;
  font-family: Arial, Helvetica, sans-serif;
  background: white;
}


