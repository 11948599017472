//
//.navbar {
//
//}

.icharts {
  background: rgb(255, 255, 255);

  pre {
    border: #0e0e0e dashed medium;
    background: red;
    padding: 5px;
    font-size: 12px;
    font-family: "courier new";
    background-color: #f5f5f5;
    border: 1px solid #cccccc;
    border-radius: 4px;
  }
}

.icharts .navbar {
  background-image: linear-gradient(#9054eb, #2fa4e7 60%, #1d9ce5);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff54b4eb', endColorstr='#ff1d9ce5', GradientType=0);
  border-bottom: 1px solid #cc17c3;
  -webkit-filter: none;
  -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);

  .navbar-default {
    background-color: #2f32e7;
    border-color: #1995dc;
  }

  .active {
    background: #0033ff;
  }
}