
//$globalH1Color: rgb(0,160,221); //leave!!!
//$globalGray: rgba(213, 213, 213, 0.35);
html {font-size: 1em;  /* 22px */}
body {
  //font-size: 100%;     /* flexible baseline */
  line-height: 1.4;
  background: #6D737723;
}
$sidePadding_phone: 2px;
$sidePadding_tablet: 5px;
$sidePadding_normal: 10%;
$sidePadding_wide: 20%;

$standardTextColor: #6d7377;

$breakpoint-width-phone: 450px;
$breakpoint-width-tablet: 1200px;
$breakpoint-width-normal: 800px;

//PHONE
@media only screen and (max-width: $breakpoint-width-phone) {
  body {
    .paragraph-1 {
      font-size: 1.0rem;
    }

    .paragraph-2 {
      font-size: 0.9rem;
    }

    .fa-page {
      //border: 5px solid green;
      margin-left: $sidePadding_phone;
      margin-right: $sidePadding_phone;
    }

    footer {
      margin-left: $sidePadding_phone;
      margin-right: $sidePadding_phone;
    }

    .Header img {
      margin-left: $sidePadding_phone;
    }
  }
}

//TABLET
@media only screen and (min-width: $breakpoint-width-phone) and (max-width: $breakpoint-width-tablet) {
  body {

    .paragraph-1 {
      font-size: 1.3rem;
    }

    .paragraph-2 {
      font-size: 0.8rem;
    }



    .fa-page {
      //border: 5px solid blue;
      margin-left: $sidePadding_tablet;
      margin-right: $sidePadding_tablet;
    }

    footer {
      padding-left: $sidePadding_tablet;
      padding-right: $sidePadding_tablet;
    }
    .Header img {
      margin-left: $sidePadding_tablet;
    }
  }
}

//NORMAL
@media only screen and (min-width: $breakpoint-width-tablet) {
  body {

    .paragraph-1 {
      font-size: 1.5rem;
    }

    .paragraph-2 {
      font-size: 1.1rem;
    }

    .fa-page {
      //border: 5px solid red;
      margin-left: $sidePadding_normal;
      margin-right: $sidePadding_normal;
    }

    footer {
      padding-left: $sidePadding_normal;
      padding-right: $sidePadding_normal;
    }

    .Header img {
      margin-left: $sidePadding_normal;
    }
  }
}

////WIDE
//@media only screen and (min-width: $breakpoint-width-normal) {
//  body {
//
//    nav {
//      padding-left: $sidePadding_wide;
//      padding-right: $sidePadding_wide;
//    }
//
//    //.navbar2 {
//    //  padding-left: $sidePadding_wide;
//    //  padding-right: $sidePadding_wide;
//    //}
//
//    .fa-page {
//      //border: 5px solid orange;
//      margin-left: $sidePadding_wide;
//      margin-right: $sidePadding_wide;
//    }
//
//    footer {
//      padding-left: $sidePadding_wide;
//      padding-right: $sidePadding_wide;
//    }
//
//    .Header img {
//      margin-left: $sidePadding_wide;
//    }
//  }
//}