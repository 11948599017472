@import "src/js/global.scss";

footer {
  font-size: 14px;
  color: #123123;
  padding: 2px;
  width: 90%;
  bottom: 0;
}

@media only screen and (max-width: $breakpoint-width-tablet) {
  footer {

    margin-left: 0rem;
  }
}